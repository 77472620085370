<template>

  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#daylycheck"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">{{year}}年{{month}}月{{dateNum}}日({{dow}})のデイリーチェック</h1>
      </div>
      <div class="control-group">
        <select
          class="form-select"
          v-model="tantouId"
          ref="tantouSelect"
        >
          <option value="">チェック担当者を選択</option>
          <option
            v-for="(staff, index) in staffList"
            :key="index"
            :value="staff.id"
          >{{staff.name}}</option>
        </select>
      </div>
    </div>
  </header>

  <main class="p-3">
    <div>      
      <ul class="list-group check-list">
        <FacilityCheck
          :tantouId="tantouId"
          :tantouName="tantouName"
        />
        <AnimalStatusCheck
          :tantouId="tantouId"
          :tantouName="tantouName"
        />
        <AnimalCountCheck
          :tantouId="tantouId"
          :tantouName="tantouName"
        />
      </ul>
    </div>
  </main>
</template>

<script>
import FacilityCheck from '@/components/CheckItem/Facility'
import AnimalStatusCheck from '@/components/CheckItem/AnimalStatus'
import AnimalCountCheck from '@/components/CheckItem/AnimalCount'

export default {
  name: "Home",
  components: {
    FacilityCheck,
    AnimalStatusCheck,
    AnimalCountCheck
  },
  data() {
    return {
      date: "",
      dateNum: "",
      tantouId: "",
      tantouName: ""
    }
  },
  created() {
    this.theDate()
    this.$store.dispatch("Staff/loadStaffList")

    setInterval(()=>{
      this.theDate()
    }, 600000)
  },
  methods: {
    theDate() {
      this.date = new Date()
      this.dateNum = this.date.getDate()
    },
  },
  computed: {
    staffList() {
      return this.$store.getters['Staff/staffList']
    },
    year() {
      return this.date.getFullYear()
    },
    month() {
      return this.date.getMonth() + 1
    },
    dow() {
      const dow = this.date.getUTCDay()
      const array = ['日','月','火','水','木','金','土']
      return array[dow] 
    },
    dateStr() {
      let date = new Date()
      date = date.toJSON()
      date = date.split('T')
      return date[0]
    }
  },
  watch: {
    tantouId(newVal) {
      if(newVal){
        let tantou = this.staffList.find(staff => staff.id === newVal )
        this.tantouName = tantou.name
      }else{
        this.tantouName = ""
      }
    },
    dateNum(oldDate, newDate) {
      if( oldDate && newDate ){
        if( oldDate !== newDate ){
          this.loadCheckList()
        }
      }
    }
  },
}
</script>

