<template>
  <b-modal
    size="lg"
    :id="modalId"
    v-model="modal"
    title="メモ追加"
    hide-footer
    @show="resetModal"
    @hidden="$emit('closeModal')"
  >
    <textarea
      class="form-control"
      v-model="content"
      placeholder="ここにメモを記入してください"
    ></textarea>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        @click="cancel"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="submit"
      >追加する</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["modalId","comment","memo"],
  data() {
    return {
      modal: false,
      contentStr: ""
    }
  },
  methods: {
    resetModal(){
      this.content = ""
    },
    submit() {
      this.$emit("addMemo", this.contentStr)
      this.modal = false
    }
  },
  computed: {
    content: {
      get() {
        return this.$props.memo
      },
      set(value) {
        this.contentStr = value
      }
    }
  },
  watch: {
    comment(newVal) {
      this.modal = newVal
    },
    modalId(newVal) {
      console.log(newVal)
    }
  }
}
</script>