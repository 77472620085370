<template>
  <li class="list-group-item">
    <div class="list-item-row">
      <div class="check-list-title">動物の頭数</div>
      <div class="check-list-group">

        <div class="animalcount">
          <label>今日の頭数</label>
          <input
            type="number"
            v-model="count"
          >
        </div>

				<div class="check-item">
					<div class="label">正常</div>
					<div class="chkbox">
						<input
							type="checkbox"
							id='count-ok'
							v-model="countOK"
						>
						<label for="count-ok"><div class="tick"></div></label>
					</div>
				</div>

				<div class="check-item">
					<div class="label">異常あり</div>
					<div class="chkbox">
						<input
							type="checkbox"
							id='count-ng'
							v-model="countNG"
						>
						<label for="count-ng"><div class="tick"></div></label>
					</div>
				</div>

        <button
          @click="openModal"
          class="btn btn-primary"
        >＋メモ</button>
        <CommentModal
          :modalId="modalId"
          :comment="comment"
          :memo="commentStr"
          @closeModal="closeModal"
          @addMemo="addMemo"
        />
      </div>
    </div>
    <div class="comment" v-if="commentStr">
      <h5>メモ</h5>
      <button
        @click="deleteComment"
        class="btn btn-secondary"
      >×</button>
      <div>{{commentStr}}</div>
    </div>
  </li>
</template>

<script>
import axios from "axios";
import CommentModal from "@/components/CommentModal"
export default {
  components: {
    CommentModal
  },
  data() {
    return {
      loading: true,
      modalId: "modalCount",
      status: null,
      count: "",
      comment: false,
      commentStr: "",
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    openModal() {
      this.comment = true
    },
    closeModal() {
      this.comment = false
    },
    addMemo(data) {
      this.commentStr = data
      this.update()
    },
    deleteComment() {
      this.commentStr = ""
      this.update()
    },
    load() {
      axios
        .get("/wp-json/pasti/v1/checklist/count")
        .then( response => {
          console.log(response)
          if(response.data.length){
            this.status = response.data[0].IsNormalAnimalNumber === "1" ? true : false
            this.commentStr = response.data[0].memo
            this.count = response.data[0].animal_number
          }else{
            this.count = localStorage.getItem("animalcount")
          }
          this.loading = false
        })
        .catch( error => {
          console.log(error);
        })
    },
    update() {
      axios
        .post("/wp-json/pasti/v1/checklist/count", {
          status: this.status,
          count: this.count,
          disinfection: this.disinfection,
          maintenance: this.maintenance,
          memo: this.commentStr,
          tantouid: this.$props.tantouId,
          tantouname: this.$props.tantouName,
        })
        .then( response => {
          console.log(response)
          localStorage.setItem("animalcount", this.count)
        })
        .catch((error) => {
          console.log(error)
        });
    }
  },
  computed: {
    countOK: {
      get() {
        return this.status
      },
      set(value) {
        this.status = value
        this.update()
      }
    },
    countNG: {
      get() {
        return this.status === false
      },
      set(value) {
        this.status = !value
        this.update()
      }
    },
  }
}
</script>

<style scoped>
.animalcount {
  width: 80px;
  margin-right: 10px;
  color: var(--bs-body-color);
  position: relative;
  top: -2px;
}
.animalcount label {
  display: block;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  margin-bottom: 4px;
}
.animalcount input {
  box-sizing: border-box;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  font-size: 22px;
  background-color: #eee;
}
.animalcount input:focus,
.animalcount input:active {
  outline: none;
}
</style>