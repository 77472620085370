<template>
  <li class="list-group-item">
    <div class="list-item-row">
      <div class="check-list-title">施設点検</div>
      <div class="check-list-group">
      
				<div class="check-item">
					<div class="label">清掃</div>
					<div class="chkbox">
						<input
							type="checkbox"
							id='cleaning'
							v-model="cleaningStatus"
              :checked="cleaning"
						>
						<label for="cleaning"><div class="tick"></div></label>
					</div>
				</div>

				<div class="check-item">
					<div class="label">消毒</div>
					<div class="chkbox">
						<input
							type="checkbox"
							id='disinfection'
							v-model="disinfectionStatus"
              :checked="disinfection"
						>
						<label for="disinfection"><div class="tick"></div></label>
					</div>
				</div>

				<div class="check-item">
					<div class="label">保守点検</div>
					<div class="chkbox">
						<input
							type="checkbox"
							id='maintenance'
							v-model="maintenanceStatus"
              :checked="maintenance"
						>
						<label for="maintenance"><div class="tick"></div></label>
					</div>
				</div>

        <button
          @click="openModal"
          class="btn btn-primary"
        >＋メモ</button>
        <CommentModal
          :modalId="modalId"
          :comment="comment"
          :memo="commentStr"
          @closeModal="closeModal"
          @addMemo="addMemo"
        />
      </div>
    </div>

    <div class="comment" v-if="commentStr">
      <h5>メモ</h5>
      <button
        @click="deleteComment"
        class="btn btn-secondary"
      >×</button>
      <div>{{commentStr}}</div>
    </div>
  </li>
</template>

<script>
import axios from "axios";
import CommentModal from "@/components/CommentModal"
export default {
  components: {
    CommentModal
  },
  props: ["tantouId","tantouName"],
  data() {
    return {
      loading: true,
      modalId: "modalFacility",
      cleaning: false,
      disinfection: false,
      maintenance: false,
      comment: false,
      commentStr: "",
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    openModal() {
      this.comment = true
    },
    closeModal() {
      this.comment = false
    },
    addMemo(data) {
      this.commentStr = data
      this.update()
    },
    deleteComment() {
      this.commentStr = ""
      this.update()
    },
    load() {
      axios
        .get("/wp-json/pasti/v1/checklist/facility")
        .then( response => {
          console.log(response)
          if(response.data.length){
            this.cleaning = response.data[0].IsCleaned === "1" ? true : false
            this.disinfection = response.data[0].IsDisinfected === "1" ? true : false
            this.maintenance = response.data[0].IsMaintained === "1" ? true : false
            this.commentStr = response.data[0].memo
          }
          this.loading = false
        })
        .catch( error => {
          console.log(error);
        })
    },
    update() {
      axios
        .post("/wp-json/pasti/v1/checklist/facility", {
          cleaning: this.cleaning,
          disinfection: this.disinfection,
          maintenance: this.maintenance,
          memo: this.commentStr,
          tantouid: this.$props.tantouId,
          tantouname: this.$props.tantouName,
        })
        .then( response => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        });
    }
  },
  computed: {
    cleaningStatus: {
      get() {
        return this.cleaning
      },
      set(value) {
        this.cleaning = value
        this.update()
      }
    },
    disinfectionStatus: {
      get() {
        return this.disinfection
      },
      set(value) {
        this.disinfection = value
        this.update()
      }
    },
    maintenanceStatus: {
      get() {
        return this.maintenance
      },
      set(value) {
        this.maintenance = value
        this.update()
      }
    },
  }
}
</script>